import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import PortfolioContext from '../../context/context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptopCode, faMobileAlt, faPaintBrush } from '@fortawesome/free-solid-svg-icons';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Us" />
        <Row>
          <Col md={{ span: 10, offset: 1 }} sm={12}>
            <Fade bottom duration={1000} delay={300} distance="30px">
              <p className="about-intro">
                Located in Bristol UK, we are a software consultancy specialising in modern website
                and app development. From JS frameworks like React to PHP development with Wordpress
                and Magento, we offer a wide range of development services.
              </p>
            </Fade>
          </Col>
        </Row>
        <Row className="about-wrapper">
          <Col lg={4} sm={12} className="service">
            <Fade bottom duration={1000} delay={500} distance="30px">
              <FontAwesomeIcon icon={faLaptopCode} className="fa" />
              <h2>Web Development</h2>
              <p>
                Specialising in fast, modern website development we can help build sites of all
                sizes. From a simple portfolio site to an e-commerce platform, we can design and
                develop a solution tailored to your needs.
              </p>
            </Fade>
          </Col>
          <Col lg={4} sm={12} className="service">
            <Fade bottom duration={1000} delay={700} distance="30px">
              <FontAwesomeIcon icon={faMobileAlt} className="fa" />
              <h2>App Development</h2>
              <p>
                Using modern hybrid development approaches such as React Native and Ionic, SK
                Digital Solutions Ltd can work with you to achieve a bespoke app design for iOS and
                Android platforms.{' '}
              </p>
            </Fade>
          </Col>
          <Col lg={4} sm={12} className="service">
            <Fade bottom duration={1000} delay={1200} distance="30px">
              <FontAwesomeIcon icon={faPaintBrush} className="fa" />
              <h2>UI Design</h2>
              <p>
                In addition to our development services we offer UI design for websites,
                applications and mobile apps. Whether you require a refresh of an existing design or
                a UI library built from scratch we are able to work with you to provide tailored
                solutions.
              </p>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
