import React from 'react';
import { navigate } from 'gatsby-link';
import Fade from 'react-reveal/Fade';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';

function encode(data) {
  return (
    Object.keys(data)
      // eslint-disable-next-line prefer-template
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  );
}

const Contact = () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      // eslint-disable-next-line no-alert
      .catch((error) => alert(error));
  };

  return (
    <section id="contact">
      <Container>
        <Title title="Work With Us" />

        <Fade bottom duration={1000} delay={400} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">Would you like to work with us?</p>
            <Row>
              <Col xs={{ span: 12, offset: 0 }} lg={{ span: 8, offset: 2 }}>
                <Form
                  className="contact-form"
                  name="contact"
                  netlify
                  data-netlify="true"
                  method="post"
                  action="/success"
                  data-netlify-honeypot="bot-field"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <Form.Group controlId="formName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      required
                      type="text"
                      placeholder="Enter name"
                      name="name"
                    />
                  </Form.Group>

                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={handleChange}
                      required
                      type="email"
                      placeholder="Enter email"
                      name="email"
                    />
                  </Form.Group>

                  <Form.Group controlId="formMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      name="message"
                      onChange={handleChange}
                      required
                      as="textarea"
                      rows={7}
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" className="cta-btn">
                    Send
                  </Button>
                </Form>
              </Col>
            </Row>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
