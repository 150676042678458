import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col, Image } from 'react-bootstrap';
import CapitaSVG from '../../images/capita.svg';
import SuperdrySVG from '../../images/superdry.svg';

import Title from '../Title/Title';

const Clients = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <Title title="Clients" />
        <Row>
          <Col md={{ span: 10, offset: 1 }} sm={10}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <p>
                We've worked with a variety of clients, and most notably the following major brands:
              </p>
            </Fade>
          </Col>
        </Row>
        <Row className="clients-wrapper">
          <Col md={{ span: 3, offset: 2 }} sm={{ span: 4, offset: 1 }} xs={{ span: 6, offset: 3 }}>
            <Fade bottom duration={1000} delay={900} distance="30px">
              <Image src={CapitaSVG} className="logos" />
            </Fade>
          </Col>
          <Col md={{ span: 3, offset: 2 }} sm={{ span: 4, offset: 1 }} xs={{ span: 6, offset: 3 }}>
            <Fade bottom duration={1000} delay={1200} distance="30px">
              <Image src={SuperdrySVG} className="logos superdry" />
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Clients;
