import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

const Footer = () => {
  const { footer } = useContext(PortfolioContext);

  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="hero" smooth duration={1000}>
            <i className="fa fa-angle-up fa-2x" aria-hidden="true" />
          </Link>
        </span>
        <p className="footer__text">SK Digital Solutions Ltd</p>

        <p className="footer__text">Bristol, England, United Kingdom</p>
        <br />
        <p className="footer__text">Monday - Friday: 9am - 5pm</p>
        <p className="footer__text">Saturday - Sunday: Closed</p>
        <br />
        <hr />
        <br />
        <p className="footer__text">© {new Date().getFullYear()} - SK Digital Solutions Ltd</p>
        <p className="footer__text">Company Registration No. 12130674</p>
      </Container>
    </footer>
  );
};

export default Footer;
